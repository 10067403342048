.bg-dingdan_sel {
    width: 16px;
    height: 19px;
    background: url('../images/navIcon_sprites.png') -10px -90px;
}

.ant-menu-item-selected .bg-dingdan_sel {
    width: 16px;
    height: 19px;
    background: url('../images/navIcon_sprites.png') -46px -90px;
}

.bg-guanggao_sel {
    width: 17px;
    height: 17px;
    background: url('../images/navIcon_sprites.png') -82px -90px;
}

.ant-menu-item-selected .bg-guanggao_sel {
    width: 17px;
    height: 17px;
    background: url('../images/navIcon_sprites.png') -119px -90px;
}

.bg-mart {
    width: 19px;
    height: 19px;
    background: url('../images/navIcon_sprites.png') -86px -10px;
}

.ant-menu-item-selected .bg-mart {
    width: 19px;
    height: 19px;
    background: url('../images/navIcon_sprites.png') -10px -51px;
}

.bg-pindao_sel {
    width: 18px;
    height: 17px;
    background: url('../images/navIcon_sprites.png') -125px -10px;

}

.ant-menu-item-selected .bg-pindao_sel {
    width: 18px;
    height: 17px;
    background: url('../images/navIcon_sprites.png') -125px -47px;
}

.bg-setting_sel {
    width: 18px;
    height: 18px;
    background: url('../images/navIcon_sprites.png') -49px -51px;
}

.ant-menu-submenu-selected .bg-setting_sel {
    width: 18px;
    height: 18px;
    background: url('../images/navIcon_sprites.png') -87px -51px;

}

.bg-shangpin_sel {
    width: 18px;
    height: 21px;
    background: url('../images/navIcon_sprites.png') -10px -10px;
}

.ant-menu-item-selected .bg-shangpin_sel {
    width: 18px;
    height: 21px;
    background: url('../images/navIcon_sprites.png') -48px -10px;
}