.SiderNav {
  background-color: #152540;
  overflow: hidden;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  z-index: 100;

  .ant-layout-sider {
    background-color: #152540;
    overflow: auto;
    width: 100%;

    .ant-layout-sider-children {
      width: 100%;
    }

    .logo {
      display: flex;
      justify-content: center;
      padding: 10px 0;
      color: #fff;
      font-size: 24px;
      font-weight: bold;

      .onlyLogo {
        width: 40px;
        height: 40px;
      }


    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background-color: #152540;
      font-size: 14px;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: #0C1A31
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: linear-gradient(to right, #448BFF, #172A49);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-10px);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff;
        z-index: 10;
      }
    }

    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
    }

    .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
      margin-right: 3px;
    }
  }

  .SiderNav-ul {
    height: calc(100vh - 112px);
    overflow: auto;

    .child-item-style {
      height: 40px;
      line-height: 40px;
    }

    .only-item-style {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #223e6b;
      margin: 0;
      height: 40px;
    }

    /* 设置滚动条的样式 */
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
