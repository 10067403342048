.mainModule {
  margin: 10px;
  flex: auto;
  background: #ffffff;
  box-sizing: border-box;
  width: calc(100% - 20px);
}

.commonPage {
  //max-width: calc(100vw - 230px);
  padding: 20px;
  box-sizing: border-box;
}

.btn-blue {
  color: #2A89FF;
  cursor: pointer;
}

.mgr20 {
  margin-right: 20px;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th, .ant-table tfoot > tr > td, .ant-table tfoot > tr > th {
  padding: 10px;
}
// 分页样式
.ant-pagination{
  margin: 15px 0 0 !important;
}

// 工具栏通用样式
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-btn-primary {
    padding: 0 20px;
    height: 34px;
    line-height: 34px;
    background: #2a89ff;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }

  .searchBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .exportBtn {
    width: 80px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #167eff;
    font-size: 14px;
    font-weight: 400;
    color: #2a89ff;
    line-height: 34px;
    padding: 0;
    margin-left: 10px;
  }

  .MarR_10 {
    margin-right: 10px;
  }

  .ant-input-affix-wrapper {
    width: 200px;
    padding: 0 16px;

    .ant-input {
      padding: 0;
      height: 30px;
      line-height: 30px;
    }
  }

  .ant-select {
    width: 200px;
  }
}

// 表格通用样式
.commonTable {
  .ant-table {
    border: 1px solid #e5e5e5;
    border-bottom: 0;

    .ant-table-thead > tr > th {
      background: #fafafa;
      border-color: #e5e5e5;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }

    .ant-table-tbody > tr > td {
      border-color: #e5e5e5;
    }
  }

  .actionBtn {
    color: #000;
    padding: 0;

    &:hover {
      color: #2a89ff;
    }
  }
}

.tycm-table {
  width: 100%;
  margin-top: 16px;

  .headerModule {
    tr {
      border: 1px solid #e5e5e5;

      th {
        height: 42px;
        font-size: 14px;
        font-weight: 400;
        color: #232323;
        text-align: center;
        padding: 10px;
      }
    }
  }

  .MidModule {
    &:before {
      content: "-";
      display: block;
      height: 15px;
      color: transparent;
    }

    tr {
      td {
        border: 1px solid #e5e5e5;

        .content {
          height: 36px;
          line-height: 36px;
          background: #f3f8ff;
          border-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          color: #191919;
          text-indent: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 15px;
            height: 15px;
            margin: 0 6px 0 20px;
          }
        }
      }
    }
  }

  tbody tr {
    border: 1px solid #e5e5e5;

    td {
      text-align: center;
      padding: 10px;
      vertical-align: top;
    }
  }

  .ProductsCp {
    position: relative;
    display: flex;
    .line{
      position: absolute;
      top: -11px;
      left: 165px;
      width: 1px;
      height: 73px;
      background: #E6E6E6;
      display: inline-block;
    }
    img {
      width: 60px;
      height: 60px;
    }

    .word-description {
      display: flex;
      flex-direction: column;
      // margin-left: 10px;
      text-align: left;

      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #232323;
      }

      b,
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}


// modal
.ant-modal {
  .ant-modal-content .ant-modal-header {
    background: #f9f9f9;

    .ant-modal-title {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  background: #f3f3f3;
  border: 0;
}

.tyys-modal-btn-primary {
  padding: 0 40px;
  height: 34px;
  line-height: 34px;
  background: #2a89ff;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.tyys-modal-btn-ghost {
  padding: 0 40px;
  height: 34px;
  line-height: 34px;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #000;

  &:hover,
  &:focus {
    background: #fff;
    color: #000;
  }
}

.tyys-delModal {
  .ant-modal-content {
    .ant-modal-header {
      background: #f9f9f9;
      padding: 0 16px;
      height: 30px;

      .ant-modal-title {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        line-height: 30px;
      }
    }

    .ant-modal-close-x {
      display: block;
      width: 56px;
      height: 30px;
      font-size: 16px;
      font-style: normal;
      line-height: 30px;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
    }

    .tyys-delModal-content {
      padding: 0 16px;
      min-width: 330px;

      .tyys-delModal-text {
        padding: 10px 0 40px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .tyys-modal-btn-primary,
  .tyys-modal-btn-ghost {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
  }
}

.tycm-input-400 {
  width: 400px;
}

.tabCommonStyle {
  .ant-tabs-tab {
    padding: 0 0 10px 0;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      width: 1px;
      height: 20px;
      background: #cecece;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    .ant-tabs-tab-btn {
      font-size: 14px;
      font-weight: 400;
      color: #818181;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #0071fe;
    }
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    background: #2a89ff;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;

    &::before {
      border-bottom: 1px solid #cccccc;
    }
  }
}
.ant-image{
  display: inline-block !important;
}
.ant-image-img{
  object-fit: contain;
}
// ant 图片样式修改
.ant-image-preview-img-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.uploadModule {
  margin-left: 114px;
}

// ele 上传样式修改
.mainImgBox {
  position: relative;

  .ant-form-item-control-input {
    height: 76px;
  }

  .tycm-upload {
    position: absolute;
    top: 0;
    left: 130px;
  }
}

.tycm-upload {
  .el-upload-list__item {
    width: 76px;
    height: 76px;
    border-radius: 0;
  }

  .el-upload--picture-card {
    width: 76px;
    height: 76px;
    border: 0;
    background: #f3f3f3;
    border-radius: 0;

    .uploadButton {
      width: 76px;
      height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: normal;
      position: relative;

      .typeTip {
        position: absolute;
        left: 80px;
        bottom: 0px;
        white-space: nowrap;
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;

        i {
          color: red;
          font-size: 12px;
        }
      }
    }
  }
}

.ant-form input[type="file"] {
  display: none;
}

.LookDetailsContent {
  height: 80vh;
  overflow: auto;

  img {
    max-width: 900px;
  }
}

.ant-statistic-content-value-decimal {
  font-size: 14px;
}

.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}


//文字两端对齐
.tycm-Justified {
  width: 100%;
  height: 100%;
  text-align-last: justify;
  text-align: justify;
  text-justify: inter-ideograph; // 这行必加，兼容ie浏览器
  display: inline-block;
  vertical-align: top;

  &:after {
    display: inline-block;
    content: '';
    overflow: hidden;
  }
}


.colorBlue {
  color: #4F9EFF;
}

.colorRed {
  color: #FF7D7A;
}

.redStar {
  color: #ff4d4f;
  margin-right: 4px;
}

.marginBottom0 {
  margin-bottom: 0;
}
