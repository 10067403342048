.Login {
  min-height: 100vh;
  background: url("../images/bg.png") center/100% 100% no-repeat;

  .login-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0 10px 13px 0 rgba(23, 54, 110, 0.21);
    border-radius: 10px;
    overflow: hidden;

    .login-left {
      width: 428px;
      height: 497px;
      background: url("../images/login-left.png") center/100% 100% no-repeat;
      position: relative;

      .logo {
        position: absolute;
        left: 45px;
        top: 28px;
        width: 197px;
        height: 65px;
      }
    }

    .login-content {
      width: 393px;
      height: 497px;
      background-color: #fff;
      background-image: url("../images/login-line.png");
      background-repeat: no-repeat;
      background-position: left bottom;

      .h2Font{
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        color: #1e7cdc;
        font-size: 26px;
      }

      .login-content-welcome {
        width: 309px;
        height: 25px;
        margin: 25px auto 0;
      }

      .login-content-title {
        width: 325px;
        font-size: 24px;
        line-height: 24px;
        color: #333333;
        margin: 36px auto 30px;
        position: relative;

        .login-content-title-underline {
          width: 51px;
          height: 7px;
          background: #1E7CDC;
          opacity: 0.2;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      form {
        width: 325px;
        margin: 0 auto;

        .login-userName {
          height: 36px;
          line-height: 36px;
          border: 1px solid #1A7ADD;
          border-radius: 4px;

          &::placeholder {
            color: #999999;
          }

          &:focus {
            border-radius: 4px;

            &::placeholder {
              color: #3490EE;
            }
          }
        }

        .ant-input-password {
          height: 36px;
          line-height: 36px;
          border: 1px solid #1A7ADD;
          border-radius: 4px;

          .ant-input {
            &::placeholder {
              color: #999999;
            }

            &:focus {
              border-radius: 4px;

              &::placeholder {
                color: #3490EE;
              }
            }
          }
        }


        .labelH {
          color: #000000;
          padding: 0 0 10px 11px;
          font-size: 14px;
        }

        .btnBox {
          position: relative;
          padding: 15px 0 0 0;

          .LoginErrorText {
            width: 100%;
            font-size: 12px;
            color: #ff6262;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
          }

          .userModalBtn {
            width: 315px;
            height: 36px;
            background: #3490EE;
            box-shadow: 0 2px 8px 0 rgba(23, 54, 110, 0.15);
            border-radius: 18px;
            padding: 0;
            font-size: 14px;
            color: #ffffff;
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .ant-form-item-explain.ant-form-item-explain-error {
          color: #FF7D7A;
        }
      }
    }
  }
}
