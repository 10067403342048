body,
ul,
li,
dl,
dt,
dd,
p,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
img,
table,
fieldset,
legend,
header,
nav,
footer,
menu,
section,
aside,
article,
canvas,
figure,
figcaption,
address {
    margin: 0;
    padding: 0;
    font-family: Microsoft YaHei;
}

ul,
li,
ol {
    list-style: none;
}

img,
fieldset {
    border: 0;
}

img {
    display: block;
}

a {
    text-decoration: none;
    color: #333;
}

i {
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

/* html,
body {
    max-width: 1920px;
    margin: 0 auto;
} */


input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #9A9A9A;
}

input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #9A9A9A;
}

input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #9A9A9A;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #9A9A9A;
}
